*{
  box-sizing: border-box;
}
.rings {
  background-image: url("./images/rings.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll;
  background-position: right;
}
input {
  outline: none;
}
.bg__review{
  background-image: url("./images/review.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  height: 100vh; 
  width: 100%;

}    

@media (max-width: 1023px) {
  .rings {
    background-image: none;
  }
  .circles {
    display: none;
  }
}
@media (max-width: 768px) {
 .bg__review{
   background-size: contain;
 }
}
@media (max-width: 425px) {
 .bg__review{
   height: 45vh;
 }
}
